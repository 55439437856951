<template>
    <span>
        {{ formattedDate }}
    </span>
</template>

<script>
    export default {
    	voVueComponent: 'dateformatter',
    	props: {
    		date: {
    			type: String, // Erwartet einen Datums-String (z. B. "Wed Dec 11 2024 00:00:00 GMT+0000")
    			required: true,
    		},
    		language: {
    			type: String, // Erwartet den Sprachcode (z. B. "de", "en-US")
    			required: true,
    		},
    	},
    	computed: {
    		formattedDate() {
    			try {
    				// Konvertiere den Datums-String in ein Date-Objekt
    				const dateObject = new Date(this.date);

    				// Überprüfe, ob das Datum gültig ist
    				if (isNaN(dateObject)) {
    					throw new Error('Ungültiges Datum');
    				}

    				// Formatierung mit Intl.DateTimeFormat
    				const formatter = new Intl.DateTimeFormat(this.language, {
    					year: 'numeric',
    					month: 'long',
    				});

    				return formatter.format(dateObject);
    			} catch (error) {
    				console.error('Fehler beim Formatieren des Datums:', error);
    				return 'Ungültiges Datum';
    			}
    		},
    	},
    };
</script>